// import React from 'react';
import React, { useState, useEffect,useCallback } from "react";
import Header from '../components/Header';
import movieafi from '../img/movie__afi.png';

import ok_btn from '../img/ok_btn.svg';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import awsmobile from "../aws-exports";
import { API } from 'aws-amplify';


interface Page4Props {
  content: string;
}

const Page4: React.FC<Page4Props> = ({ content }) => {


    const [roomId, setRoomId] = useState<string | null>(null);
    const [data, setData] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();
    const [id, setId] = useState<string | null>(null);
    const [user, setUser] = useState<string | null>(null);

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const idParam = queryParams.get('id');
      const userParam = queryParams.get('user');
      setId(idParam);
      setUser(userParam);
      // console.log(location.state.example)

    }, []); // <-- この空の配列により、副作用は一度だけ実行されます。


    // Fetch data
    // const callApi = async (jsondata:Record<string,any>) => {
    const fetchData = useCallback(async () => {
      try {
        const response = await fetch("https://e6nddvtimj.execute-api.ap-northeast-1.amazonaws.com/nijikaipro/waitCheck", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ roomid: roomId }),
        });
        if (response.ok) {
          const result = await response.json();
          console.log("success");
          setData(result);
          // console.log(result);

          // console.log(result.answer.length);
          // console.log(result.room[0].room__json__attr["NumberPeople"]);
          // jsonreturn = result;

          if(result.answer.length >= result.room[0].room__json__attr["NumberPeople"]){
            setIsVisible(true)
          }

        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        // console.log("success4");
        console.error("Error fetching data:", error);
      }
    }, [roomId]);

    // Set room ID and fetch data
    useEffect(() => {
      setRoomId(id);
      if (id) {
        fetchData();
        
        // callApi(api__send__json as Record<string, any>);
      }
    }, [id, fetchData]);

      // const navigate = useNavigate(); //ページ遷移させるために必要

      // navigate(`/wait?id=${api__send__json.roomid}&user=${api__send__json.answerid}`, { state: { example: api__send__json.room__json__attr } });

  return (
    <>
      <Header title="My App" />
      <section className='room__setting'>

        <div>
          <h2>
            お相手が入力中です…<br/>
            お待ちください<br/>
          </h2>
          <p className='mt-3 mb-3'>
            ※ブラウザの戻るボタンを押してしまうと最初からやり直しになりますのでご注意ください
          </p>
          <figure className='m-auto'>
            <img src={movieafi} className='m-auto' alt="QRコード" />
          </figure>
          {/* <p>
            ↑ ↑ ↑スキャンしてルームに入る↑ ↑ ↑
          </p> */}
          {/* <input type ="image" name="submit" className='w-4/5 m-auto' src={ok_btn} alt="送信" handleChildData={}/> */}
          {/* <Link to="/return">確認ページへ行く</Link> */}


               {/* <input
                type="text"
                placeholder="Enter roomid"
                value={roomId}
                onChange={(e) => setRoomId(e.target.value)}
              />
              <button onClick={fetchData}>Fetch Data</button> */}

              {/* {data && (
                <div>
                  <h2>Data:</h2>
                  <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
              )} */}
              <button className="bg-blue-800 hover:bg-blue-800 text-white rounded px-4 py-2 mt-3 mb-3" onClick={fetchData}>
                相手が入力したかチェックする。
              </button>
              <p>※相手の入力が終わると下にボタンが出ます</p>


              {isVisible && (
                <div className="mt-3 mb-2">
                  <Link to={`/room_2?id=${id}&user=${user}`} className="bg-blue-800 hover:bg-blue-800 text-white rounded px-4 py-2 mt-3 mb-3">本音入力ページへ行く</Link>
                </div>
              )}


        </div>
      </section>
    </>
  );
};

export default Page4;
