// import React from 'react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import machi_btn from '../img/machi_btn.svg';
import ok_btn from '../img/ok_btn.svg';

import Modalcomp from '../components/Modalcomp';

import { useLocation } from 'react-router-dom';

import { nanoid } from 'nanoid';//IDをつくるnpm

import { API } from 'aws-amplify';


interface Page3Props {
  content: string;
}

const Page3: React.FC<Page3Props> = ({ content }) => {
  

  //IDをつくる
  const generateUniqueId = () => {
      const date = new Date();
      const dateString = date.toISOString().replace(/[:\-T]/g, "").slice(0, 14); // YYYYMMDDHHmmss のフォーマットに変換
      // const randomId01 = nanoid(3);
      const randomId02 = nanoid(5);
      return "user-" + dateString + randomId02;
  }

  // パラメータからIDを取得
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  console.log(id);


  //作成日をつくる
  // const createdAt = new Date().toISOString();
  // const [createdAt, setDate] = useState('');
  const [createdAt, setDateTime] = useState('');
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset());
    currentDate.setHours(currentDate.getHours() + 9); // 日本は UTC+9 なので 9 を足す
    const dateTimeString = currentDate.toISOString().replace('Z', '').replace('T', ' ');
    setDateTime(dateTimeString);
  }, []);

  // form内に存在する
  const handleClick = () => {
    const form = document.getElementById("myForm");
    const result: Record<string, any> = {};

    if (!form) return;

    if (form) {
      
      const inputs = form.getElementsByTagName("input");
      const prefix = "prefix_";
      // const result: { [key: string]: string } = {};
      
      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const { name, value, type, checked } = input;

        if (name.startsWith(prefix)) {
          if (type === "checkbox"||type === "radio") {
            if (checked) {
              result[name] = value;
            }
          } else {
            result[name] = value;
          }
        }
      }
    }
    console.log(JSON.stringify(result));
    return result
  };

  const [dataFromChild, setDataFromChild] = React.useState('');//modalから値を受け取るために必要。
  const handleChildData = (data: string) => {//モーダルでOKが返ってきた時に入力する場所
    setDataFromChild(data);
  };

  const navigate = useNavigate(); //ページ遷移させるために必要

  // APIを呼び出す
  const callApi = async (jsondata:Record<string,any>) => {
    // const apiEndpoint = awsmobile.aws_cloud_logic_custom[0].endpoint;

    try {
      const response = await API.post('roomApi', '/anserfunc', {
        headers: {
          "Content-Type": "application/json",
        },
        body: jsondata,
      });
    
      console.log(response);
      console.log("API call succeeded");

      // const userId = 123;
      navigate(`/wait?id=${api__send__json.roomid}&user=${api__send__json.answerid}`);

      // history.push({
      //   pathname: `/wait?id=${api__send__json.roomid}&user=${api__send__json.answerid}`,
      //   state: { example: 'Hello from previous page!' }
      // });

    } catch (error) {
      console.error("API call failed:", error);
    }
  };

  //APIに送信するJSON
  const api__send__json = {
    roomid:id,
    answerid:generateUniqueId(),
    create_ad:createdAt,
    update_ad:createdAt,
    room__json__attr:handleClick(),
  };

  // モーダルの値がtureでLambdaに送信する
  useEffect(() => {
    // 必要に応じて、API 通信を行う
    // APIを呼び出す
    if(dataFromChild){
        // console.log("成功している"); 
        // const jsondata =  handleClick();
        callApi(api__send__json as Record<string, any>);
    }
  }, [dataFromChild]);

  return (
       <div>
        <Header title="My App" />
        <section className='room__setting'>
          <div>
            <h2>
              このあとどうする？
            </h2>
            <p>
              あなたの本音にチェックを入れてください。
              お相手の人とチェックした項目がマッチしたものだけ、
              お互いに通知されます。「勘違い」や「恥をかく」なんてことが、無くなります！
            </p>
          </div>
          <div>
            <h2>
              2次会 本音箱 本音ルーム
            </h2>
            <div className="w-full md:w-1/2 px-3 mb-6 flex flex-wrap">
              <form id="myForm">

                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" htmlFor="grid-first-name">
                    ニックネーム
                  </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="お名前を入力してください" name='prefix_name'/>
                  {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                </div>


                <div className="w-full md:w-1/2 px-3 mb-6 flex flex-wrap">

                  <p className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 w-full	text-left	mb-2'>                
                    年齢
                  </p>    
                  <div className="flex items-center mb-4 mr-8">
                      <input id="cb-age01" type="radio" value="20代" name='prefix_age' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                      <label htmlFor="cb-age01" className="ml-2 text-sm font-medium text-gray-900">
                        20代
                      </label>
                  </div>

                  <div className="flex items-center mb-4 mr-8">
                      <input id="cb-age02" type="radio" value="30代" name='prefix_age' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                      <label htmlFor="cb-age02" className="ml-2 text-sm font-medium text-gray-900">
                        30代
                      </label>
                  </div>

                  <div className="flex items-center mb-4 mr-8">
                      <input id="cb-age03" type="radio" value="40代" name='prefix_age' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                      <label htmlFor="cb-age03" className="ml-2 text-sm font-medium text-gray-900">
                        40代
                      </label>
                  </div>

                  <div className="flex items-center mb-4 mr-8">
                      <input id="cb-age04" type="radio" value="50代" name='prefix_age' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                      <label htmlFor="cb-age04" className="ml-2 text-sm font-medium text-gray-900">
                        50代
                      </label>
                  </div>

                  <div className="flex items-center mb-4 mr-8">
                      <input id="cb-age05" type="radio" value="60代以上" name='prefix_age' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                      <label htmlFor="cb-age05" className="ml-2 text-sm font-medium text-gray-900">
                        60代以上
                      </label>
                  </div>

                </div>


                <div className="w-full md:w-1/2 px-3 mb-6 flex flex-wrap">

                  <p className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 w-full	text-left	mb-2'>                
                    性別
                  </p>    
                  <div className="flex items-center mb-4 mr-8">
                      <input id="checkbox-sex1" type="radio" value="男性" name='prefix_sex' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                      <label htmlFor="checkbox-sex1" className="ml-2 text-sm font-medium text-gray-900">
                        男性
                      </label>
                  </div>

                  <div className="flex items-center mb-4 mr-8">
                      <input id="checkbox-sex2" type="radio" value="女性" name='prefix_sex' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                      <label htmlFor="checkbox-sex2" className="ml-2 text-sm font-medium text-gray-900">
                        女性
                      </label>
                  </div>

                  <div className="flex items-center mb-4 mr-8">
                      <input id="checkbox-sex3" type="radio" value="それ以外" name='prefix_sex' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                      <label htmlFor="checkbox-sex3" className="ml-2 text-sm font-medium text-gray-900">
                        それ以外
                      </label>
                  </div>

                </div>



                {/* <input type ="image" name="submit" className='w-4/5 m-auto' src={machi_btn} onClick={handleClick} alt="送信"/> */}
                {/* <button type="button" onClick={handleClick}>Submit</button> */}
                {/* <Modalcomp title="回答を送信します" content="よろしいですか？" btn={machi_btn} handleChildData={handleChildData}/> */}
                <Modalcomp title="回答を送信します" content="よろしいですか？" btn={ok_btn} handleChildData={handleChildData}/>

               </form> 

              </div>

          </div>

      </section>
    </div>

  );
};

export default Page3;
