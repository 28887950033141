import React from 'react';
import './App.css';
// import Header from './components/Header';
import Footer from './components/Footer';
import Page1 from './pages/host';
import Page2 from './pages/Qrcreate';
import Page3 from './pages/Room';
import Page4 from './pages/wait';
import Page3_2 from './pages/Room_2';
import Page4_2 from './pages/wait_2';

import Page5 from './pages/return';
import Page6 from './pages/Page6';
import Page7 from './pages/Page7';
import HomePage from './components/HomePage';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


// import image from './img/logo.svg';
// import main_ill from './img/main_illa.png';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* Add routes for other pages as needed */}
        </Routes>

        {/* <Header title="My App" /> title プロパティを追加 */}
        {/* ナビゲーションリンクを追加 */}
        {/* <nav>
          <ul>
            <li>
              <Link to="/page1">Page 1</Link>
            </li>
            <li>
              <Link to="/page2">Page 2</Link>
            </li>
            <li>
              <Link to="/page3">Page 3</Link>
            </li>
            <li>
              <Link to="/page4">Page 4</Link>
            </li>
            <li>
              <Link to="/page5">Page 5</Link>
            </li>
            <li>
              <Link to="/page6">Page 6</Link>
            </li>
          </ul>
        </nav> */}
        <Routes>
          <Route path="/host" element={<Page1 content="Page 1 Content" />} />
          <Route path="/Qrcreate" element={<Page2 content="Page 2 Content" />} />
          <Route path="/Room" element={<Page3 content="Page 3 Content" />} />
          <Route path="/wait" element={<Page4 content="Page 4 Content" />} />
          <Route path="/Room_2" element={<Page3_2 content="Page 3 Content" />} />
          <Route path="/wait_2" element={<Page4_2 content="Page 4 Content" />} />
          <Route path="/return" element={<Page5 content="Page 5 Content" />} />
          <Route path="/page6" element={<Page6 content="Page 6 Content" />} />
          <Route path="/page7" element={<Page7 content="Page 7 Content" />} />
        </Routes>
        <Footer /> {/* title プロパティを追加 */}

      </div>
    </Router>
  );
}

export default App;

