import React, { useState, useEffect,useCallback } from "react";
import Header from '../components/Header';
import return_btn from '../img/return_btn.svg';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



interface Page5Props {
  content: string;
}

const Page5: React.FC<Page5Props> = ({ content }) => {

    const [roomId, setRoomId] = useState<string | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();
    const [id, setId] = useState<string | null>(null);
    const [user, setUser] = useState<string | null>(null);
  
    // let myObj: { [key: string]: any } = {};
    // const [data, setData] = useState(null);

    const [data, setData] = useState<{ [key: string]: any }[] | null>(null);
    const [myObj, setMyObj] = useState<{ [key: string]: any }[] | null>(null);



    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const idParam = queryParams.get('id');
      const userParam = queryParams.get('user');
      setId(idParam);
      setUser(userParam);
    }, []); // <-- この空の配列により、副作用は一度だけ実行されます。


    // Fetch data
    // const callApi = async (jsondata:Record<string,any>) => {
    const fetchData = useCallback(async () => {
      try {
        const response = await fetch("https://e6nddvtimj.execute-api.ap-northeast-1.amazonaws.com/nijikaipro/waitCheck", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ roomid: roomId }),
        });

        //
        // アンサーで回答した内容を取得する
        //
        if (response.ok) {
          const result = await response.json();
          // console.log("success");
          // // setData(result);

          console.log(result);
          // console.log(user);

          // console.log(result.room[0].room__json__attr["NumberPeople"]);
          // jsonreturn = result;

          // const answer_json = [];
          let answer_json: { [key: string]: any }[] = [];

        
          // let myObj :[key: string]: any;  // ここでは任意のキーと値を持つことができます
          let myObj: { [key: string]: any } = {};


          for (let i = 0; i < result.answer.length; i++) {
            const element = result.answer[i];
            // console.log(element);
            if(element.answerid === user){
              // console.log("これは自分です："+element.answerid );
              myObj = element;
              continue;
            }
          }

          console.log("myObj");
          console.log(myObj.room__json__attr.prefix_name);

          setMyObj([myObj]);

          for (let i = 0; i < result.answer.length; i++) {
            let element = result.answer[i];

            //自分の回答はcontinue
            if(element.answerid === user){
              // console.log("これは自分です："+element.answerid );
              continue;
            }

            //自分の回答と一致する回答を取得する
            // let commonKeysAndValues = Object.keys(myObj.anwser__json__attr)
            //   .filter(key => element.anwser__json__attr.hasOwnProperty(key))
            //   .map(key => ({ [key]: myObj.anwser__json__attr[key] }));

            //自分の回答と一致する回答を取得する
            let commonKeysAndValues = Object.keys(element.anwser__json__attr)
              .filter(key => myObj.anwser__json__attr.hasOwnProperty(key))
              .map(key => ({ [key]: element.anwser__json__attr[key] }));

            console.log("commonKeysAndValues");
            console.log(commonKeysAndValues);
            
            // 3人以上の回答があった場合
            // 自分の回答と一致する相手の回答を取得
            // nestの値を取得して照合する
            let parentOfNest = findParentOfNest(commonKeysAndValues);
            // console.log(parentOfNest);

            if(parentOfNest){
              // console.log("処理をいれる")
              // // let nestArray = parentOfNest.parent.nest;
              // console.log(nestArray)
              // for (let index = 0; index < nestArray.length; index++) {
              //     // ここで各要素に対する処理を行う
              //     const partner_anser = nestArray[index];
              //     // console.log(partner_anser === myObj.answerid);
              //     // console.log(element.room__json__attr.room__json__attr.prefix_name);
              //     // consoe.log(parentOfNest);

              // }

              //
              // 一致した回答の中でnestがある回答がある場合にその回答が自分と一致するか確認する
              //
              parentOfNest.forEach(result => {
                  let nestArray = result.parent.nest;
                  // console.log(nestArray);
                  // console.log(result.parent);
                  for (let index = 0; index < nestArray.length; index++) {
                      // 相手のnest要素の中を取得 例）user-20230730123313dhlm8 など
                      const partner_anserid = nestArray[index];
                      // console.log(`partner_anserid：${partner_anserid}`);

                      //対象の項目キーを取得する 例）jsonnest_korekaramdg など
                      const mykey = result.key.toString();
                      // console.log(mykey);

                      //自分の回答の中と一致するか確認するため、自分のnest要素を取得
                      let myAnser = myObj.anwser__json__attr[mykey].nest
                      // console.log(`myAnser：${myAnser}`);

                      // パートナーIDを取得
                      let pid = element.answerid
                      // let pid = element.room__json__attr.answerid
                      // console.log(`pid：${pid}`);

                      let pkeyid = element.anwser__json__attr[mykey].nest
                      // console.log(`pkeyid：${pkeyid}`);

                      //パートナー名を取得
                      let pname = element.room__json__attr.prefix_name
                      // let pname = element.room__json__attr.room__json__attr.prefix_name
                      // console.log(`pname：${pname}`);

                      //項目の値を取得する
                      let attrval = result.parent.value
                      // console.log(`attrval：${attrval}`);

                      let uniquekey = mykey+"_"+pid

                      console.log("myAnser")
                      // console.log(element)

                      console.log(myAnser[0])
                      // console.log(pid)
                      // console.log(myObj.answerid)
                      // console.log(pkeyid)

                      // const hasMatchingValue = myAnser.some((item: string) => pkeyid.includes(item));

                      // 自分の回答と相手の回答が一致するか確認する
                      // myObj.answerid
                      if (myAnser.includes(pid)&&pkeyid.includes(myObj.answerid)) {
                      // if (hasMatchingValue) {
                      // if (myAnser.includes(myObj.answerid)) {
                      // if (myAnser.includes(pid) && myAnser.includes(myObj.answerid)) {
                        console.log(`「 ${pname} 」さんとマッチしました。：${attrval}`);
                        commonKeysAndValues.push({[uniquekey]:`「 ${pname} 」さんとマッチしました。：${attrval}`})
                      } else if(myAnser[0] === "all"){
                        console.log(`「 全員 」さんとマッチしました。：${attrval}`);
                        commonKeysAndValues.push({[uniquekey]:`「 ${pname} 」さんとマッチしました。：${attrval}`})

                        // console.log('partner_anseridは配列の中に存在しません。');
                      } else{
                        console.log('誰ともマッチしませんでした');
                      }


                      // 配列を文字列に変換して格納する
                      // resultが対象の配列になるはずなので内容確認する

                      // const mykey = result.key.toString();
                      // console.log(partner_anserid === myObj.answerid);
                      // console.log(element.room__json__attr.room__json__attr.prefix_name);
                      // console.log(result.key);
                      // console.log("自分のキー");
                      // console.log(myObj.anwser__json__attr[mykey].nest);
                  }
              });
            }

            // ******************************************
            // 0903一旦削除
            // ******************************************

            // let answer_user_list ={
            //   id:element.answerid,
            //   name:element.room__json__attr.prefix_name,
            //   answer:commonKeysAndValues
            // }
            // answer_json.push(answer_user_list);

            // commonKeysAndValuesには以下のような配列が入っていることを想定
            // [
            //     {"prefix_hone01": "ご馳走さまでした（もう帰ります）"},
            //     {"prefix_hone02": "終電までなら付き合います"
            //     }
            // ]
            console.log(commonKeysAndValues);
            // Object.assign(answer_json[0], commonKeysAndValues);


            // answer_json.push(commonKeysAndValues);

            // answer_jsonの中にオブジェクトのみで格納する
            answer_json = [...answer_json, ...commonKeysAndValues];

          }

          // ******************************************
          // 0903一旦削除
          // 完成したJSONの中で配列形式があった場合は削除したい。
          // 重複項目についても削除する
          // ******************************************

          // const keysSet = new Set();

          // answer_json.forEach(user => {
          //   user.answer = user.answer.filter(item => {
          //     // オブジェクトの各プロパティを調べ、valueとnestプロパティを持つものを除外
          //     if (Object.values(item).some(prop => prop && typeof prop === 'object' && 'value' in prop && 'nest' in prop)) {
          //       return false;
          //     }

          //     const key = Object.keys(item)[0];

          //     if (keysSet.has(key)) {
          //       return false; // キーが既に存在する場合、この項目を削除
          //     } else {
          //       keysSet.add(key); // そうでない場合、キーを保存
          //       return true; // この項目を保持
          //     }
          //   });
          // });       



          // ******************************************
          // 完成したJSONの中で配列形式があった場合は削除したい。
          // 重複項目についても削除する
          // オブジェクトの各プロパティを調べ、valueとnestプロパティを持つものを除外
          // ******************************************

          const uniqueKeys = new Set<string>();
          const uniqueArr: { [key: string]: string }[] = [];

          answer_json.forEach(obj => {
            if (Object.values(obj).some(prop => prop && typeof prop === 'object' && 'value' in prop && 'nest' in prop)) {
                return false;
            }
            const key = Object.keys(obj)[0];  // 仮定: 各オブジェクトには1つのキーしかない
           
            if (!uniqueKeys.has(key)) {
              uniqueKeys.add(key);
              uniqueArr.push(obj);
            }
          });

          answer_json = uniqueArr;  // 結果を answer_json に上書き

          
          console.log("answer_json")
          console.log(answer_json)

          // ******************************************
          // ダイレクトメッセがある場合
          // 自分宛のマッチングある場合のみ
          // ******************************************
          console.log("result")
          console.log(result)
          console.log(result.answer)
          // マッチしていないDMも入っている可能性があるので一度削除してからマッチしているかの確認をする

          // if (answer_json.length > 0) {
          //   let obj02 = [{}];  // オブジェクトの配列
          //   // answerがない場合はオブジェクトを追加する
          //   // answer_json[0].answer = obj02;
          // }
          console.log(answer_json)
          
          // delete answer_json[0].direct_msg

          // "direct_msg" キーを持つオブジェクトを除外
          answer_json = answer_json.filter(obj => !('direct_msg' in obj));

          console.log(answer_json)

          // console.log(result.answer)
          for (let index = 0; index < result.answer.length; index++) {
            console.log('ダイレクトメッセージのelement')
            let element = result.answer[index]

            //  console.log(element)

            if(element.anwser__json__attr.hasOwnProperty("direct_msg")){
              const ele = element.anwser__json__attr.direct_msg
              console.log(element.answerid)
              //console.log(ele.d_name[0])
              for (let eleindex = 0; eleindex < ele.d_name.length; eleindex++) {
                const d_name = ele.d_name[eleindex];
                const d_text = ele.d_text[0];
                // console.log(d_name)
                if(d_name === myObj.answerid){
                   console.log('マッチしました')
                   console.log(`【${element.room__json__attr.prefix_name}さん】が${d_text}と言っています`)
                  // answer_json.push({id:"000",answer:[{unmatched:`【${element.room__json__attr.prefix_name}さん】が${d_text}と言っています`}]})
                  
                  // console.log(answer_json[0].answer.direct_msg)
                  // answer_json[0].answer[0]['direct_msg'+element.answerid+'_'+eleindex] = `【${element.room__json__attr.prefix_name}さん】からメッセージ：${d_text}`

                  answer_json.push({['direct_msg'+element.answerid+'_'+eleindex]: `【${element.room__json__attr.prefix_name}さん】からメッセージ：${d_text}`});

                }else if(d_name === ' ' && element.answerid !== myObj.answerid) {
                  console.log('マッチしていません')
                  console.log(element.answerid)
                  console.log(myObj.answerid)
                  // answer_json[0].answer[0]['direct_msg'+element.answerid+'_'+eleindex] = `【${element.room__json__attr.prefix_name}さん】から全員にメッセージ：${d_text}`
                  answer_json.push({['direct_msg'+element.answerid+'_'+eleindex]: `【${element.room__json__attr.prefix_name}さん】から全員にメッセージ：${d_text}`});

                }
              }

            }
          }


          console.log("answer_json");
          console.log(answer_json.length);
          // ******************************************
          // answer_jsonの配列が空の時の対応
          // ******************************************
          // if (answer_json.every(item => item.answer.length === 0)) {
          //   console.log("すべてのanswer配列が空です");
          //   answer_json.push({unmatched:`マッチはされませんでした。。`})

          // } else {
          //   console.log("1つ以上のanswer配列に要素があります");
          // }

          if (answer_json.length === 0){
            console.log("すべてのanswer配列が空です");
            answer_json.push({unmatched:`マッチはされませんでした。。`})

          } else {
            console.log("1つ以上のanswer配列に要素があります");
          }


          // setData(answer_json);

          setData(answer_json);

          // if(result.answer.length >= result.room[0].room__json__attr["NumberPeople"]){
          //   setIsVisible(true)
          // }

          // 共通キーをチェックするための関数
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.log("success4");
        // console.error("Error fetching data:", error);
      }
    }, [roomId]);

    // Set room ID and fetch data
    useEffect(() => {
      setRoomId(id);
      if (id) {
        fetchData();
        
        // callApi(api__send__json as Record<string, any>);
      }
    }, [id, fetchData]);


    //親構造を取得する
    // function findParentOfNest(obj: any): any {
    //     if (Array.isArray(obj)) {
    //         for (let i in obj) {
    //             let result: any = findParentOfNest(obj[i]);
    //             if (result) return result;
    //         }
    //     } else if (typeof obj == 'object' && obj !== null) {
    //         for (let key in obj) {
    //             if (obj[key] && typeof obj[key] === 'object' && 'nest' in obj[key]) return obj[key];
    //             let result: any = findParentOfNest(obj[key]);
    //             if (result) return result;
    //         }
    //     }
    //     return null;
    // }

    //親構造を取得する。ネストになっているキーを取り出し
    function findParentOfNest(obj: any): Array<{ parent: any, key: string }> {
        let results: Array<{ parent: any, key: string }> = [];
        if (Array.isArray(obj)) {
            for (let i in obj) {
                results = results.concat(findParentOfNest(obj[i]));
            }
        } else if (typeof obj == 'object' && obj !== null) {
            for (let key in obj) {
                if (obj[key] && typeof obj[key] === 'object' && 'nest' in obj[key]) {
                    results.push({ parent: obj[key], key: key });
                }
                results = results.concat(findParentOfNest(obj[key]));
            }
        }
        return results;
    }

    // 表示しないkeyを指定しておく
    const excludeKeys = ["prefix_age", "prefix_sex", "prefix_name"];


  return (
    <>
        <Header title="My App" />
        <section className='room__setting'>
        <div>
          <div className="return">
            <h2>
              マッチした<br/>
              お互いの本音です！！
            </h2>

            {/* <ul>
              <li>・終電までなら付き合います</li>
              <li>・手を繋ぐOK</li>
              <li>・2人きりになりたい</li>
              <li>・静かな場所でもう1杯</li>
            </ul> */}

        
            <div className="">
                  {/* <pre>{JSON.stringify(myObj, null, 2)}</pre>  */}
                {myObj && <h3>{myObj[0].room__json__attr.prefix_name} さん のマッチ結果です</h3>}
                {/* <pre>{JSON.stringify(data, null, 2)}</pre>  */}

                {/* {data && data.map((item) => (
                  <div key={item.id}>
                    <ul>
                      {item.answer.map((answerItem: any, index: any) => (
                        <li key={index}>
                          {Object.entries(answerItem)
                            .filter(([key]) => !excludeKeys.includes(key)) // 除外リストに含まれないキーのみをフィルタリング
                            .map(([key, value]) => (
                            <span key={key}>{`${value}`}</span>
                          ))}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))} */}

                 {data && data.map((item, index) => {
                    const key = Object.keys(item)[0];
                    const value = item[key];

                    return (
                      <div key={index}>
                        {/* <h3>{key}</h3> */}
                        <p>{value}</p>
                      </div>
                    );
                  })}
            </div>
            
            
            {
            /* 
            {data && (
                <div>
                  <h2>Data:</h2>
                  <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
              )} */
              }

          </div>
          <p>
            これからどうするか決まりましたか？<br/>
            上手くできなくて、もう一度試したい場合は
          </p>

          <div className='w-4/5 m-auto mt-6 mb-6'>
              <Link to="/host">
                <figure>
                  <img src={return_btn} alt="ルーム作成ボタンに戻る" />
                </figure>
              </Link>
          </div>
        </div>
        
      </section>
    </>
  );
};

export default Page5;
