import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Modalcomp from '../components/Modalcomp';

import ok_btn from '../img/ok_btn.svg';


import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { CreateTodoInput, CreateTodoMutation, UpdateTodoMutation, DeleteTodoMutation } from '../API';
import { ListTodosQuery } from '../API';
import { OnCreateTodoSubscription } from '../API';

import { GraphQLSubscription } from '@aws-amplify/api';

import { createTodo, updateTodo, deleteTodo } from '../graphql/mutations';
import { listTodos } from '../graphql/queries';
import { onCreateTodo } from '../graphql/subscriptions';

// import { Observable } from 'rxjs';
import { Observable } from "zen-observable-ts";


interface Page7Props {
  content: string;
}

interface Todo {
  id: string;
  name: string;
  description: string;
}

const Page7: React.FC<Page7Props> = ({ content }) => {
  // const [showModal, setShowModal] = React.useState(false);

  //modalから値を受け取るために必要。
  // const [dataFromChild, setDataFromChild] = React.useState('');
  // const handleChildData = (data: string) => {
  //   console.log("Data from child component:", data);
  //   setDataFromChild(data);
  //   console.log("Data from child component:", setDataFromChild);
  // };
  const [todos, setTodos] = useState<Todo[]>([]);



  useEffect(() => {
    async function mutations() {//書き込み
        const todo: CreateTodoInput = { name: "My first todo111", description: "こんにちは！Hello world!112"};

        /* create a todo */
        await API.graphql<GraphQLQuery<CreateTodoMutation>>(graphqlOperation(createTodo, {input: todo}));
        console.log("sucsuss")
        /* update a todo */
        // await API.graphql<GraphQLQuery<UpdateTodoMutation>>(graphqlOperation(updateTodo, { input: { id: 5, name: "Updated todo info111" }}));

        // /* delete a todo */
        // await API.graphql<GraphQLQuery<DeleteTodoMutation>>(graphqlOperation(deleteTodo, { input: { id: 5 }}));

        // ...do something with result...
    }
    mutations()

    async function queries() {//書き込み
        const queries = await API.graphql<GraphQLQuery<ListTodosQuery>>(graphqlOperation(listTodos));
        console.log("queries")
        console.log(queries)
    }
    queries()


    // Subscribe to creation of Todo
    const sub = API.graphql<GraphQLSubscription<OnCreateTodoSubscription>>(
        graphqlOperation(onCreateTodo)
    ).subscribe({
        next: (payload) => {
          const createdTodo = payload.value.data?.onCreateTodo;
          console.log(createdTodo);
        }
    });
    console.log(sub.unsubscribe)
    sub.unsubscribe();

    // サブスクリプションをセットアップします
    // const subscription = (API.graphql(
    //   graphqlOperation(onCreateTodo), {}
    // ) as Observable<any>).subscribe({
    //   next: (todoData: any) => {
    //     const todo = todoData.value.data.onCreateTodo;
    //     setTodos((prevTodos) => [...prevTodos, todo]);
    //   },
    // });

    // return () => subscription.unsubscribe();

    // const result = API.graphql(
    //   graphqlOperation(onCreateTodo), {}
    // );

    // let subscription;
    // if ('subscribe' in result) { // Observable をチェック
    //     subscription = result.subscribe({
    //         next: (todoData: any) => {
    //             const todo = todoData.value.data.onCreateTodo;
    //             setTodos((prevTodos) => [...prevTodos, todo]);
    //         },
    //     });
    // } else {
    //     console.log('The result is not an Observable.');
    // }

    const subscription = (API.graphql(
        graphqlOperation(onCreateTodo)
      ) as Observable<any>).subscribe({
        next: (todoData: any) => {
          const todo = todoData.value.data.onCreateTodo;
          setTodos((prevTodos) => [...prevTodos, todo]);
        }
    });

  // クリーンアップ関数。コンポーネントのアンマウント時にサブスクリプションを解除します
  return () => subscription.unsubscribe();

  }, []);




  return (
    <div>
      {/* <Header title="My App" /> */}


      <h2>Page 7</h2>

      <h2>Page 7</h2>
      <p>{content}</p>


      {/* <Modalcomp title="ROOMを作ります。" content="よろしいですか？" btn={ok_btn} handleChildData={handleChildData}/> */}
      <div>
         {todos.map((todo) => (
        <div key={todo.id}>
          <h2>{todo.name}</h2>
          <p>{todo.description}</p>
          {/* <p>{todo.test}</p> */}
        </div>
      ))}
      </div>

    </div>
  );
};

export default Page7;



// export default function Modal() {
//   const [showModal, setShowModal] = React.useState(false);
//   return (
//   );