import React from 'react';
import QRCode from 'react-qr-code';

interface QRCodeComponentProps {
  value: string;
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ value }) => {
  return (
    <div className='qr_code'>
      <QRCode value={value} />
    </div>

  );
};

export default QRCodeComponent;
