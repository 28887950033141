// src/components/HomePage.tsx
import React from 'react';

import image from '../img/logo.svg';
import main_ill from '../img/main_illa.png';
import aff_img from '../img/1676679306_34630453.png';
import room_img from '../img/room_btn.svg';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


const HomePage = () => {
  return (
    <div>
      {/* <h1>Welcome to the Home Page</h1> */}
      {/* Add your unique content for the Home Page here */}
      <header className="index_top">
          <h1>
            <figure className="logo">
              <img src={image} alt="2次会本音箱" />
            </figure>
          </h1>
          <h2 className="h2_midashi">
            このあとどうする？<br/>
            <span>あなたの本音にチェックを</span><br/>
            入れてください。
          </h2>

          <div className="top_img_02">
            <p>
              お相手の人とチェックした項目が<br/>
              マッチしたものだけ、<br/>
              お互いに通知されます。<br/><br/>

              「<span className="text-red-500">勘違い</span>」や「<span className="text-red-500">恥をかく</span>」<br/>
              なんてことが、<span className="text-red-500">無くなります！</span>
            </p>
            <figure>
              <img src={main_ill} alt="Image logo" />
            </figure>
          </div>
        </header>

        {/* <section className="sec__affi">
            <figure>
              <img src={aff_img} alt="" />
            </figure>
        </section> */}

        <section className='sec_room'>
            <Link to="/host">
              <figure>
                <img src={room_img} alt="ルーム作成ボタン" />
              </figure>
            </Link>
        </section>
    </div>
  );
};

export default HomePage;
