import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
// import qr from '../img/qr.png';
import { useLocation } from 'react-router-dom';

import QRCodeComponent from '../components/QRCodeComponent';//QRつくるやつ

import Modalcomp from '../components/Modalcomp';

import ok_btn from '../img/ok_btn.svg';

// QR作成
// import { API } from 'aws-amplify';


interface Page2Props {
  content: string;
}

const Page2: React.FC<Page2Props> = ({ content }) => {
  // const { myParam } = useParams();
  // console.log(myParam);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  console.log(id);

  // const currentPageURL = window.location.origin + location.pathname + location.search;
  const currentPageURL = window.location.origin;

  const qrValue = currentPageURL + '/room?id=' + id;

  const [dataFromChild, setDataFromChild] = React.useState('');//modalから値を受け取るために必要。
  const handleChildData = (data: string) => {//モーダルでOKが返ってきた時に入力する場所
    setDataFromChild(data);
  };

  const navigate = useNavigate(); //ページ遷移させるために必要
  useEffect(() => {
    // 必要に応じて、API 通信を行う
    // APIを呼び出す
    if(dataFromChild){
        // console.log("成功している"); 
        // callApi();
        navigate(`/room?id=`+ id);
    }
  }, [dataFromChild]);


  

  // // QR作成
  // const [response, setResponse] = useState('');

  // const callLambda = async () => {
  //   try {
  //     const apiName = 'roomApi';
  //     const path = '/qr';
  //     const options = {
  //       body: {
  //         key1: 'value1',
  //         key2: 'value2',
  //       },
  //     };
  //     const result = await API.post(apiName, path, options);
  //     setResponse(JSON.stringify(result));
  //   } catch (error) {
  //     console.error(error);
  //     setResponse('Error calling Lambda function');
  //   }
  // };

  return (
    <>
        <Header title="My App" />
        <section className='room__setting'>
        <div>

          <h2>
            以下のQRコードを<br/>
            お相手に見せてください
          </h2>

          <QRCodeComponent value={qrValue} />

          <p>
            ↑ ↑ ↑スキャンしてルームに入る↑ ↑ ↑
          </p>

          <Modalcomp title="ROOMに入ります。" content="よろしいですか？" btn={ok_btn} handleChildData={handleChildData}/>

        </div>

    
        {/* <div>
          <button onClick={callLambda}>Call Lambda</button>
          <p>{response}</p>
        </div> */}

      </section>
    </>
  );
};

export default Page2;
