import React from 'react';
import aff_img from '../img/1676679306_34630453.png';



const Footer: React.FC = () => {
  return (
    <div>
      
      {/* <section className="sec__affi">
          <figure>
            <img src={aff_img} alt="" />
          </figure>
      </section> */}

      <footer>
        <div>
          <a href='https://allwrite.co.jp/プライバシーポリシー（個人情報保護方針）/'>プライバシーポリシー</a>
          <a href='https://allwrite.co.jp/company/'>運営会社</a>
          <a href='https://forms.gle/cDXLBZsZFBEqT7Sy8'>アンケートフォームはこちら</a>
        </div>
        <p>&copy; 2023 All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Footer;
