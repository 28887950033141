import React from 'react';

import image from '../img/logo.svg';

import aff_img_top from '../img/1676679306_34630453.png';

// import HomePage from '../components/HomePage';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';



interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <div>
      <header>
        <Link to="/">
          {/* <h1>{title}</h1> */}
          <h1>
            <figure className="logo logo_temp">
              <img src={image} alt="2次会本音箱" />
            </figure>
          </h1>
        </Link>
      </header>
      {/* <section className="sec__affi">
          <figure>
            <img src={aff_img_top} alt="" />
          </figure>
      </section> */}
    </div>
  );
};

export default Header;
