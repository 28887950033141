import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';

import ok_btn from '../img/ok_btn.svg';

import Modalcomp from '../components/Modalcomp';

import { nanoid } from 'nanoid';//IDをつくるnpm

import awsmobile from "../aws-exports";
import { API } from 'aws-amplify';






interface Page1Props {
  content: string;
}

const Page1: React.FC<Page1Props> = ({ content }) => {

  //ニックネーム
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  // 人数
  const [inputPeople, setSelectedValue01] = useState("");
  const handleRadio_People_Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue01(event.target.value);
  };

  // 年齢
  const [inputAge, setSelectedValue02] = useState("");
  const handleRadio_Age_Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue02(event.target.value);
  };

  // 性別
  const [inputSex, setSelectedValue03] = useState("");
  const handleRadio_Sex_Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue03(event.target.value);
  };

  // シチュエーション
  const [inpuSituation, setSelectedValue04] = useState("");
  const handleRadio_Situation_Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue04(event.target.value);
  };

  //roomの設定情報が入る
  let room__json__attr ={
      "nickname":inputValue,
      "NumberPeople":inputPeople,
      "age":inputAge,
      "sex":inputSex,
      "situation":inpuSituation
  };

  //IDをつくる
  const generateUniqueId = () => {
      const date = new Date();
      const dateString = date.toISOString().replace(/[:\-T]/g, "").slice(0, 14); // YYYYMMDDHHmmss のフォーマットに変換
      // const randomId01 = nanoid(3);
      const randomId02 = nanoid(5);
      return "room-" + dateString + randomId02;
  }
  
  //作成日をつくる
  // const createdAt = new Date().toISOString();
  // const [createdAt, setDate] = useState('');
  const [createdAt, setDateTime] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset());
    currentDate.setHours(currentDate.getHours() + 9); // 日本は UTC+9 なので 9 を足す
    const dateTimeString = currentDate.toISOString().replace('Z', '').replace('T', ' ');
    setDateTime(dateTimeString);
  }, []);

  const api__send__json = {
    roomid:generateUniqueId(),
    create_ad:createdAt,
    update_ad:createdAt,
    room__json__attr:room__json__attr,
  };

  const navigate = useNavigate(); //ページ遷移させるために必要

  const [dataFromChild, setDataFromChild] = React.useState('');//modalから値を受け取るために必要。
  const handleChildData = (data: string) => {//モーダルでOKが返ってきた時に入力する場所
    setDataFromChild(data);
  };


  // APIを呼び出す
  const callApi = async () => {
    // const apiEndpoint = awsmobile.aws_cloud_logic_custom[0].endpoint;

    try {
      const response = await API.post('roomApi', '/roomCreate', {
        headers: {
          "Content-Type": "application/json",
        },
        body: api__send__json,
      });
    
      console.log(response);
      // const userId = 123;
      navigate(`/Qrcreate?id=${api__send__json.roomid}`);

      // navigate('/room');
      console.log("API call succeeded");
    } catch (error) {
      console.error("API call failed:", error);
    }
  };
  
  // モーダルの値がtureでLambdaに送信する
  useEffect(() => {
    // 必要に応じて、API 通信を行う
    // APIを呼び出す
    if(dataFromChild){
        // console.log("成功している"); 
        callApi();
    }
  }, [dataFromChild]);

  return (
    <>
      <Header title="My App" />
      
      <section className='room__setting'>
        <div>
          {/* {{room__json__attr}} */}
          <h2>あなたがルームを作ります。</h2>
          <p className="mb-3">
            以下項目に記入し<br/>
            OKボタンをタップしてください。
          </p>

          <form id='room' className="w-full max-w-lg">
            <div className="flex flex-wrap mb-6">

              {/* <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" htmlFor="grid-first-name">
                  ニックネーム
                </label>
                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane" onChange={handleInputChange}/>
              </div> */}

              <div className="w-full md:w-1/2 px-3 mb-6 flex flex-wrap">

                <p className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 w-full	text-left	mb-2'>                
                  人数
                </p>    
                <div className="flex items-center mb-4 mr-8">
                    <input id="cb-people01" type="radio" value="2" name='people' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleRadio_People_Change}/>
                    <label htmlFor="cb-people01" className="ml-2 text-sm font-medium text-gray-900">
                      2人
                    </label>
                </div>

                <div className="flex items-center mb-4 mr-8">
                    <input id="cb-people02" type="radio" value="3" name='people' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleRadio_People_Change}/>
                    <label htmlFor="cb-people02" className="ml-2 text-sm font-medium text-gray-900">
                      3人
                    </label>
                </div>

                <div className="flex items-center mb-4 mr-8">
                    <input id="cb-people03" type="radio" value="4" name='people' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleRadio_People_Change}/>
                    <label htmlFor="cb-people03" className="ml-2 text-sm font-medium text-gray-900">
                      4人以上
                    </label>
                </div>
              </div>


              <div className="w-full md:w-1/2 px-3 mb-6 flex flex-wrap">

                <p className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 w-full	text-left	'>                
                  シチュエーション
                </p>    
                <div className="flex items-center mb-4 mr-8">
                    <input id="checkbox-situation1" type="radio" value="合コン" name='situation' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleRadio_Situation_Change}/>
                    <label htmlFor="checkbox-situation1" className="ml-2 text-sm font-medium text-gray-900">
                      合コン
                    </label>
                </div>

                <div className="flex items-center mb-4 mr-8">
                    <input id="checkbox-situation2" type="radio" value="初デート" name='situation' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleRadio_Situation_Change}/>
                    <label htmlFor="checkbox-situation2" className="ml-2 text-sm font-medium text-gray-900">
                      初デート
                    </label>
                </div>

                <div className="flex items-center mb-4 mr-8">
                    <input id="checkbox-situation3" type="radio" value="何度目かのデート" name='situation' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleRadio_Situation_Change}/>
                    <label htmlFor="checkbox-situation3" className="ml-2 text-sm font-medium text-gray-900">
                      何度目かのデート
                    </label>
                </div>

                <div className="flex items-center mb-4 mr-8">
                    <input id="checkbox-situation4" type="radio" value="それ以外" name='situation' className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleRadio_Situation_Change}/>
                    <label htmlFor="checkbox-situation4" className="ml-2 text-sm font-medium text-gray-900">
                      それ以外
                    </label>
                </div>
              </div>

              {/* <input type ="image" name="submit" className='w-4/5 m-auto' src={ok_btn} alt="送信"/> */}

              <Modalcomp title="ROOMを作ります。" content="よろしいですか？" btn={ok_btn} handleChildData={handleChildData}/>


            </div>
          </form>


        </div>
      </section>


    </>
  );
};

export default Page1;
