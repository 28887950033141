import React from 'react';
import Header from '../components/Header';
import Modalcomp from '../components/Modalcomp';

import ok_btn from '../img/ok_btn.svg';


interface Page6Props {
  content: string;
}

const Page6: React.FC<Page6Props> = ({ content }) => {
  // const [showModal, setShowModal] = React.useState(false);

  //modalから値を受け取るために必要。
  const [dataFromChild, setDataFromChild] = React.useState('');
  const handleChildData = (data: string) => {
    console.log("Data from child component:", data);
    setDataFromChild(data);
    console.log("Data from child component:", setDataFromChild);
  };


  return (
    <div>
      <Header title="My App" />



      <h2>Page 6</h2>
      <p>{content}</p>


      <Modalcomp title="ROOMを作ります。" content="よろしいですか？" btn={ok_btn} handleChildData={handleChildData}/>


    </div>
  );
};

export default Page6;



// export default function Modal() {
//   const [showModal, setShowModal] = React.useState(false);
//   return (
//   );