// import React from 'react';
// import React, { useState, useEffect } from 'react';
import React, { useState, useEffect,useCallback,ChangeEvent } from "react";
import { MyAttr } from './types';

import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import machi_btn from '../img/machi_btn.svg';
import Modalcomp from '../components/Modalcomp';

import { useLocation } from 'react-router-dom';

import { nanoid } from 'nanoid';//IDをつくるnpm

import { API } from 'aws-amplify';


interface Page3_2Props {
  content: string;
}

const Page3_2: React.FC<Page3_2Props> = ({ content }) => {
  

  // //IDをつくる
  // const generateUniqueId = () => {
  //     const date = new Date();
  //     const dateString = date.toISOString().replace(/[:\-T]/g, "").slice(0, 14); // YYYYMMDDHHmmss のフォーマットに変換
  //     // const randomId01 = nanoid(3);
  //     const randomId02 = nanoid(5);
  //     return "user-" + dateString + randomId02;
  // }

  // パラメータからIDを取得
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const userParam = queryParams.get('user');

// console.log(id);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [my_attr, setData] = useState<{ [key: string]: any }[] | null>(null);
  const [my_sex, setSEX] = useState<{ [key: string]: any }[] | null>(null);
  // const [dataall, setDataall] = useState(null);
  const [dataall, setDataall] = useState<{ [key: string]: any }[] | null>(null);
  const [roomattr, setRoom] = useState<{ [key: string]: any }[] | null>(null);

  // console.log(data)
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch("https://e6nddvtimj.execute-api.ap-northeast-1.amazonaws.com/nijikaipro/waitCheck", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ roomid: roomId }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("success");
        console.log(result);

        // const targetKey = userParam;

        // if (targetKey !== null) {
        //   const index = result.answer.findIndex((obj: {[key: string]: any}) => targetKey in obj);
        //   console.log(index); // 0
        //   setData(result);
        // }　

        // const targetKey = 'yourKey';
        // const index = result.answer.findIndex((obj: {[key: string]: any}) => keyExists(obj, targetKey));
        // console.log(targetKey);

        // if (targetKey !== null) {
        //     const index = result.answer.findIndex((obj: {[key: string]: any}) => keyExists(obj, targetKey));
        //     console.log(index);
        // }

        const targetValue = userParam;
        const index = result.answer.findIndex((obj: {[key: string]: any}) => valueExists(obj, targetValue));
        // console.log(userParam);

        // console.log("index");  // 指定した値が存在する最初のオブジェクトのインデックスが表示されます

        // console.log(index);  // 指定した値が存在する最初のオブジェクトのインデックスが表示されます
        // console.log(result.answer[index].room__json__attr.prefix_sex);  // 指定した値が存在する最初のオブジェクトのインデックスが表示されます
        
        setData(result.answer[index]);
        setSEX(result.answer[index].room__json__attr.prefix_sex);

        setDataall(result.answer);
        setRoom(result.room);
        // console.log(result.answer.length);
        // console.log(result.room[0].room__json__attr["NumberPeople"]);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.log("success4");
      // console.error("Error fetching data:", error);
    }
  }, [roomId]);

  // keyを検索する関数
  // function keyExists(obj: {[key: string]: any}, targetKey: string): boolean {
  //   for (let key in obj) {
  //     if (key === targetKey) {
  //       return true;
  //     }
  //     if (typeof obj[key] === 'object' && obj[key] !== null) {
  //       if (keyExists(obj[key], targetKey)) {
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // }

  // 値を検索する
  function valueExists(obj: {[key: string]: any}, targetValue: any): boolean {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            if (valueExists(obj[key], targetValue)) {
                return true;
            }
        } else if (obj[key] === targetValue) {
            return true;
        }
    }
    return false;
}


  // Set room ID and fetch data
  useEffect(() => {
    setRoomId(id);
    if (id) {
      fetchData();
      // callApi(api__send__json as Record<string, any>);
    }
  }, [id, fetchData]);


  //作成日をつくる
  // const createdAt = new Date().toISOString();
  // const [createdAt, setDate] = useState('');
  const [createdAt, setDateTime] = useState('');
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset());
    currentDate.setHours(currentDate.getHours() + 9); // 日本は UTC+9 なので 9 を足す
    const dateTimeString = currentDate.toISOString().replace('Z', '').replace('T', ' ');
    setDateTime(dateTimeString);
  }, []);


    const [value, setValue] = useState(' ');

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setValue(e.target.value);
    };


  /*
    form内に存在する項目を取得する
    先頭に「prefix_」についている項目を取得
   */
  const handleClick = () => {
    const form = document.getElementById("myForm");
    const result: Record<string, any> = {};

    if (!form) return;

    if (form) {
      
      const inputs = form.getElementsByTagName("input");
     //// const select_ele = form.getElementsByTagName("select");


      // HTMLCollection を配列に変換
     // const inputsArray = Array.from(inputs_ele);
     // const selectArray = Array.from(select_ele);

      // 二つの配列を結合
     // const inputs = [...inputsArray, ...selectArray];



      console.log(inputs)
      const prefix = "prefix_";
      const jsonnest = "jsonnest_";
      const direct_msg = "direct_msg";
      // const result: { [key: string]: string } = {};
      // let mysetanswer;

      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const { name, value, type, checked } = input;
        // mysetanswer = result[name];

        // console.log("mysetanswer");
        // console.log(mysetanswer);
        if (name.startsWith(prefix)) {
          if (type === "checkbox") {
            if (checked) {
              result[name] = value;
            }

          } else {
            result[name] = value;
          }
        }
        if(name.startsWith(jsonnest)){
            const [parentKey, childKey] = name.split('.');

            // console.log("parentKey");
            // console.log(parentKey);
            // console.log("childKey");
            // console.log(childKey);

            if (type === "checkbox") {
              if (checked) {
                if (childKey) {
                  if (!result[parentKey]) {
                    result[parentKey] = {};
                     if (!Array.isArray(result[parentKey])) {
                        result[parentKey] = [];
                      }
                  }
                 
                  if (!result[parentKey][childKey]) {
                    result[parentKey][childKey] = {};
                    if (!Array.isArray(result[parentKey][childKey])) {
                      result[parentKey][childKey] = [];
                    }
                  }
                
                  result[parentKey][childKey].push(value);
                  // result[parentKey][childKey] = {value};
                } else {

                  console.log("きている")
                  console.log(value)
                  // if (!result[parentKey]) {
                  //   result[parentKey] = {};
                  // }
                  // if (!Array.isArray(result[parentKey])) {
                  //   result[parentKey] = [];
                  // }
                  // result[parentKey].push(value);
                  result[name] = { value };
                }
              }

            }else {
              result[name] = value;
            }

        } else if(name.startsWith(direct_msg)){
          const [parentKey, childKey] = name.split('.');

          console.log('direct_msg')
          console.log(parentKey)
          console.log(childKey)

          const elementsWithNameDirectMsg = document.querySelectorAll('input[name="direct_msg"]');

          let isChecked = false;

          elementsWithNameDirectMsg.forEach(element => {
            const inputElement = element as HTMLInputElement;

                  //console.log(value)
            if (inputElement.checked) {
              isChecked = true;
            }
          });

          // if (isChecked) {
          //   console.log('少なくとも1つの"direct_msg"名前の要素がチェックされています。');
          // } else {
          //   console.log('名前が"direct_msg"の要素はチェックされていません。');
          // }

          if (isChecked) {
                if (childKey) {
                  if (!result[parentKey]) {
                    result[parentKey] = {};
                     if (!Array.isArray(result[parentKey])) {
                        result[parentKey] = [];
                     }
                  }
                 
                  if (!result[parentKey][childKey]) {
                    result[parentKey][childKey] = {};
                    if (!Array.isArray(result[parentKey][childKey])) {
                      result[parentKey][childKey] = [];
                    }
                  }
                  result[parentKey][childKey].push(value);
                  // result[parentKey][childKey] = {value};
                } else {

                  console.log("きている")
                  console.log(value)
                  // if (!result[parentKey]) {
                  //   result[parentKey] = {};
                  // }
                  // if (!Array.isArray(result[parentKey])) {
                  //   result[parentKey] = [];
                  // }
                  // result[parentKey].push(value);
                  result[name] = { value };
                }
              

            }
        }else {
          // result[name] = value;
        }

      }
    }
    console.log("JSON.stringify(result)");
    console.log(JSON.stringify(result));
    return result
  };



  const [dataFromChild, setDataFromChild] = React.useState('');//modalから値を受け取るために必要。
  const handleChildData = (data: string) => {//モーダルでOKが返ってきた時に入力する場所
    setDataFromChild(data);
  };

  const navigate = useNavigate(); //ページ遷移させるために必要

  // APIを呼び出す
  const callApi = async (jsondata:Record<string,any>) => {
    // const apiEndpoint = awsmobile.aws_cloud_logic_custom[0].endpoint;

    try {
      const response = await API.post('roomApi', '/anserfunc', {
        headers: {
          "Content-Type": "application/json",
        },
        body: jsondata,
      });
    
      console.log(response);
      console.log("API call succeeded");

      // const userId = 123;
      // 選択された項目を取得して送信し、次のページに遷移する
      navigate(`/wait_2?id=${api__send__json.roomid}&user=${api__send__json.answerid}`);
      // navigate('/room');
    } catch (error) {
      console.error("API call failed:", error);
    }
  };

  console.log("my_attr")
  // console.log(my_attr);
  // if (my_attr && 'room__json__attr' in my_attr) {
  //     console.log(my_attr.room__json__attr);
  // }
  console.log((my_attr as any)?.room__json__attr);


  //APIに送信するJSON
  const api__send__json = {
    roomid:id,
    answerid:userParam,
    update_ad:createdAt,
    anwser__json__attr:handleClick(),
    room__json__attr: (my_attr as any)?.room__json__attr
  };

  console.log("api__send__json")
  console.log(api__send__json)



  // モーダルの値がtureでLambdaに送信する
  useEffect(() => {
    // 必要に応じて、API 通信を行う
    // APIを呼び出す
    // console.log("data")
    // console.log(data)
    if(dataFromChild){
        // console.log("成功している"); 
        // const jsondata =  handleClick();

        //APIの送信する関数
        //ここを後でコメントアウトを解除する
        callApi(api__send__json as Record<string, any>);
    }
  }, [dataFromChild]);



  const [isChecked, setIsChecked] = useState(false);



  // チェックボックスの変更イベントハンドラ
const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setIsChecked(event.target.checked);
};

  // プルダウンの選択変更イベントハンドラ
const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // 選択値を利用するなどの処理を実装
  };

// console.log(my_attr.roomid)


  return (
       <div>
        <Header title="My App" />
        <section className='room__setting'>
          <div>
            <h2>
              このあとどうする？
            </h2>
            <p>
              あなたの本音にチェックを入れてください。
              お相手の人とチェックした項目がマッチしたものだけ、
              お互いに通知されます。「<span className="text-red-500">勘違い</span>」や「<span className="text-red-500">恥をかく</span>」なんてことが、<span className="text-red-500">無くなります！</span>
            </p>
          </div>
          <div>
            <h2>
              2次会 本音箱 本音ルーム
            </h2>
            <div className="w-full px-3 mb-6 flex flex-wrap">
              <form id="myForm">
                <h2 className='items-center mb-4 px-3 mt-5'>
                  本音を入力
                </h2>
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="default-checkbox" type="checkbox" value="ご馳走さまでした（もう帰ります）" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone01'/>
                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900">
                      ご馳走さまでした（もう帰ります）
                    </label>
                </div>

                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox1" type="checkbox" value="終電までなら付き合います" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone02'/>
                    <label htmlFor="checked-checkbox1" className="ml-2 text-sm font-medium text-gray-900">
                      終電までなら付き合います
                    </label>
                </div>

                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox2" type="checkbox" value="朝まででも付き合います" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone03'/>
                    <label htmlFor="checked-checkbox2" className="ml-2 text-sm font-medium text-gray-900">
                      朝まででも付き合います
                    </label>
                </div>

                <div className="px-3 flex items-center mb-4 mr-8 flex-wrap">
                    <input id="checked-checkbox3" type="checkbox" value="手を繋ぐOK" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_tewo'/>
                    <label htmlFor="checked-checkbox3" className="ml-2 text-sm font-medium text-gray-900">
                      手を繋ぐOK
                    </label>
                    
                    {/* 宛先を表示する */}

                        

                        {
                          /* 
                            3人以上の時にのみ表示するアンケート内容
                            同じ性別の場合は表示しない
                            「.」で区切ることによって子要素として表示できる
                          */
                          roomattr && roomattr[0]?.room__json__attr.NumberPeople > 2 && (
                            // 条件が true の場合の要素
                            <>
                              <div className="flex w-full flex-wrap items-center bg-gray-100 rounded-md p-2">
                                <span className="flex flex-wrap items-center">
                                  <input id="three03-all" type="checkbox" value="all" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_tewo.nest'/>
                                  <label htmlFor="three03-all" className="ml-2 text-sm font-medium text-gray-900 mr-8">
                                      全員
                                  </label>
                                </span> 
                              
                                {dataall && dataall.map((item: any) => (
                                      item.answerid !== userParam && item.room__json__attr.prefix_sex !== my_sex ? (
                                      <span className="flex flex-wrap items-center">
                                          <input id={`three03-${item.answerid}`} type="checkbox" value={item.answerid} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_tewo.nest'/>
                                          <label htmlFor={`three03-${item.answerid}`} className="ml-2 text-sm font-medium text-gray-900 mr-8">
                                            {item.room__json__attr.prefix_name}
                                          </label>
                                        </span>
                                      ) : null
                                ))}
                              </div>

                            </>

                        )}

                </div>

                <div className="px-3 flex items-center mb-4 mr-8 flex-wrap">
                    <input id="checked-checkbox4" type="checkbox" value="2人きりになりたい" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_hutari'/>
                    <label htmlFor="checked-checkbox4" className="ml-2 text-sm font-medium text-gray-900">
                      2人きりになりたい
                    </label>

                     {/* 宛先を表示する */}
                       
                        
                        {
                          /* 
                            3人以上の時にのみ表示するアンケート内容
                            同じ性別の場合は表示しない
                            「.」で区切ることによって子要素として表示できる
                          */
                          roomattr && roomattr[0]?.room__json__attr.NumberPeople > 2 && (
                            // 条件が true の場合の要素
                            <>
                            <div className="flex w-full flex-wrap items-center bg-gray-100 rounded-md p-2">

                             <span className="flex flex-wrap items-center">
                                <input id="three04-all" type="checkbox" value="all" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_hutari.nest'/>
                                <label htmlFor="three04-all" className="ml-2 text-sm font-medium text-gray-900 mr-8">
                                    全員
                                </label>
                              </span>
                                {dataall && dataall.map((item: any) => (
                                      item.answerid !== userParam && item.room__json__attr.prefix_sex !== my_sex ? (
                                      <span className="flex flex-wrap items-center">
                                          <input id={`three04-${item.answerid}`} type="checkbox" value={item.answerid} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_hutari.nest'/>
                                          <label htmlFor={`three04-${item.answerid}`} className="ml-2 text-sm font-medium text-gray-900 mr-8">
                                            {item.room__json__attr.prefix_name}
                                          </label>
                                        </span>
                                      ) : null
                                ))}
                                </div>
                            </>
                        )}
                </div>
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox5" type="checkbox" value="静かな場所でもう1杯" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone06'/>
                    <label htmlFor="checked-checkbox5" className="ml-2 text-sm font-medium text-gray-900">
                      静かな場所でもう1杯
                    </label>
                </div>
               
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox7" type="checkbox" value="ホテルに行こう" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone08'/>
                    <label htmlFor="checked-checkbox7" className="ml-2 text-sm font-medium text-gray-900">
                      ホテルに行こう
                    </label>
                </div>
                
                <div className="px-3 flex items-center mb-4 mr-8 flex-wrap">
                    <input id="checked-checkbox8" type="checkbox" value="これからもメッセージを重ねましょう" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_korekaramdg'/>
                    <label htmlFor="checked-checkbox8" className="ml-2 text-sm font-medium text-gray-900">
                      これからもメッセージを重ねましょう
                    </label>

                    {/* 宛先を表示する */}
                  
                        
                        {
                          /* 
                            3人以上の時にのみ表示するアンケート内容
                            同じ性別の場合は表示しない
                            「.」で区切ることによって子要素として表示できる
                          */
                          roomattr && roomattr[0]?.room__json__attr.NumberPeople > 2 && (
                            // 条件が true の場合の要素
                            <>
                              <div className="flex w-full flex-wrap items-center bg-gray-100 rounded-md p-2">
                                <span className="flex flex-wrap items-center">
                                  <input id="three01-all" type="checkbox" value="all" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_korekaramdg.nest'/>
                                  <label htmlFor="three01-all" className="ml-2 text-sm font-medium text-gray-900 mr-8">
                                      全員
                                  </label>
                                </span>
                                {dataall && dataall.map((item: any) => (
                                      item.answerid !== userParam && item.room__json__attr.prefix_sex !== my_sex ? (
                                      <span className="flex flex-wrap items-center">
                                          <input id={`three01-${item.answerid}`} type="checkbox" value={item.answerid} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_korekaramdg.nest'/>
                                          <label htmlFor={`three01-${item.answerid}`} className="ml-2 text-sm font-medium text-gray-900 mr-8">
                                            {item.room__json__attr.prefix_name}
                                          </label>
                                        </span>
                                      ) : null
                                ))}
                                </div>

                            </>
                        )}

                </div>


                <div className="px-3 flex items-center mb-4 mr-8 flex-wrap">
                    <input id="checked-checkbox9" type="checkbox" value="気になる" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_kininaru'/>
                    <label htmlFor="checked-checkbox9" className="ml-2 text-sm font-medium text-gray-900">
                      気になる
                    </label>

                    {/* 宛先を表示する */}
                        
                        {
                          /* 
                            3人以上の時にのみ表示するアンケート内容
                            同じ性別の場合は表示しない
                            「.」で区切ることによって子要素として表示できる
                          */
                          roomattr && roomattr[0]?.room__json__attr.NumberPeople > 2 && (
                            // 条件が true の場合の要素
                            <>
                              <div className="flex w-full flex-wrap items-center bg-gray-100 rounded-md p-2">

                              <span className="flex flex-wrap items-center">
                                <input id="three02-all" type="checkbox" value="all" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_kininaru.nest'/>
                                <label htmlFor="three02-all" className="ml-2 text-sm font-medium text-gray-900 mr-8">
                                    全員
                                </label>
                              </span>
                                {dataall && dataall.map((item: any) => (
                                      item.answerid !== userParam && item.room__json__attr.prefix_sex !== my_sex ? (
                                      <span className="flex flex-wrap items-center">
                                          <input id={`three02-${item.answerid}`} type="checkbox" value={item.answerid} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='jsonnest_kininaru.nest'/>
                                          <label htmlFor={`three02-${item.answerid}`} className="ml-2 text-sm font-medium text-gray-900 mr-8">
                                            {item.room__json__attr.prefix_name}
                                          </label>
                                        </span>
                                      ) : null
                                ))}               
                              </div>
                            </>
                        )}

                </div>

                
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox9-2" type="checkbox" value="近くを散歩したい" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone10'/>
                    <label htmlFor="checked-checkbox9-2" className="ml-2 text-sm font-medium text-gray-900">
                      近くを散歩したい
                    </label>
                </div>
                
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox10" type="checkbox" value="カラオケに行きたい" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone11'/>
                    <label htmlFor="checked-checkbox10" className="ml-2 text-sm font-medium text-gray-900">
                      カラオケに行きたい
                    </label>
                </div>
                
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox11" type="checkbox" value="飲み以外の何かをしよう" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone12'/>
                    <label htmlFor="checked-checkbox11" className="ml-2 text-sm font-medium text-gray-900">
                      飲み以外の何かをしよう
                    </label>
                </div>
                
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox12" type="checkbox" value="もう1軒OK" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone13'/>
                    <label htmlFor="checked-checkbox12" className="ml-2 text-sm font-medium text-gray-900">
                      もう1軒OK
                    </label>
                </div>
                
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox13" type="checkbox" value="漫画喫茶でまったりしたい" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone14'/>
                    <label htmlFor="checked-checkbox13" className="ml-2 text-sm font-medium text-gray-900">
                      漫画喫茶でまったりしたい
                    </label>
                </div>
                
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox14" type="checkbox" value="お相手に合わせる" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_hone15'/>
                    <label htmlFor="checked-checkbox14" className="ml-2 text-sm font-medium text-gray-900">
                      お相手に合わせる
                    </label>
                </div>
                
                <div className="px-3 flex items-center mb-4 mr-8">
                    <input id="checked-checkbox15" type="checkbox" value="その他（ダイレクトメッセージ）" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='direct_msg' checked={isChecked} onChange={handleCheckboxChange}/>
                    <label htmlFor="checked-checkbox15" className="ml-2 text-sm font-medium text-gray-900">
                      その他（ダイレクトメッセージ）
                    </label>
                </div>


                {/* <pre>{roomattr && JSON.stringify(roomattr[0]?.room__json__attr.NumberPeople, null, 2)}</pre> */}

                {isChecked && (
                      <div className="px-3 items-center mb-4 block bg-gray-100 rounded-md p-4">
                        <select className="border border-solid w-full block border-gray-300 rounded-md" onChange={handleChange}>
                        {/* <input id="checked-checkbox-all" type="checkbox" value="all" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_directmsg'/>
                        <label htmlFor="checked-checkbox-all" className="ml-2 text-sm font-medium text-gray-900">
                          全員に送る
                        </label> */}

                          <option value="all">
                            全員に送る
                          </option>


                        {/* <div className="block w-full"> */}
                          {dataall && dataall.map((item: any) => (
                                // <option key={item.answerid} value= {item.answerid}>{item.room__json__attr.prefix_name}</option>
                                item.answerid !== userParam ? (
                                  <option key={item.answerid} value={item.answerid}>
                                    {item.room__json__attr.prefix_name}
                                  </option>
                                  // <>
                                  //   <input id={`checked-checkbox-${item.answerid}`} type="checkbox" value={item.answerid} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name='prefix_directmsg'/>
                                  //   <label htmlFor={`checked-checkbox-${item.answerid}`} className="ml-2 text-sm font-medium text-gray-900">
                                  //     {item.room__json__attr.prefix_name}
                                  //   </label>
                                  // </>
                                ) : null
                          ))}
                        {/* </div> */}
                        </select>
                        
                        <input type="text" value={value} onChange={handleChange} name="direct_msg.d_name" disabled={true} className="hidden"/>

                        <div className="block w-full">
                          <label htmlFor="textfield" className="block text-sm font-medium text-gray-700"></label>
                          <input id="textfield" type="text" placeholder="入力してください" className="mt-1 px-3 py-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" name="direct_msg.d_text"/>
                        </div>

                      </div>
                  )}


                {/* {dataall.room.room__json__attr.NumberPeople === 2 && <p>数字は4です。</p>} */}

              {/* <pre>{JSON.stringify(dataall, null, 2)}</pre> */}


                {/* <input type ="image" name="submit" className='w-4/5 m-auto' src={machi_btn} onClick={handleClick} alt="送信"/> */}
                {/* <button type="button" onClick={handleClick}>Submit</button> */}
                <Modalcomp title="回答を送信します" content="よろしいですか？" btn={machi_btn} handleChildData={handleChildData}/>

               </form> 

              </div>

          </div>

      </section>
    </div>

  );
};

export default Page3_2;
